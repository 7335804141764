import React, {useState, useEffect} from 'react'
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import {selectUser } from '../auth/users/userSlice'
import firebase from "firebase";
import { db } from '../auth/firebase'
import './InvoiceLast.css'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Divider, Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import PrintIcon from '@mui/icons-material/Print';
import { Link } from 'react-router-dom';
import {businessname, bstreet, bcity, bemail, bphone} from '../models/European'
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import logo from '../assets/image/logo.png'

const SearchEstimate = () => {
    const user = useSelector(selectUser)
  const [url, setUrl] = useState(null);
  const [todos, setTodos] = useState([]);

  const getProfileImage = () => {
    // firebase.storage().ref(`users/${user.uid}/cropped-GottaWebDesign-02-6-scaled-345x219-1-160x93.jpg`).getDownloadURL().then(imgUrl => {setUrl(imgUrl)})
    firebase
    .storage()
    .ref('users')
    .child(user.uid + "/logo.png")
    .getDownloadURL()
    .then((imgUrl) => {
      setUrl(imgUrl);
    });
  }

  useEffect(() => {
    setTimeout(() => {
      getProfileImage();
    }, 1000);
  }, [])



 // Get Todos
 const  getTodos = () => {
   db.collection(user.uid).onSnapshot(function(querySnapshot) {
     setTodos(
       querySnapshot.docs.map((doc) => ({
         id: doc.id,
         bcity: doc.data().bcity,
         bemail: doc.data().bemail,
         bstreet: doc.data().bstreet,
         businessname: doc.data().businessname,
         bphone: doc.data().bphone,
         bwebsite: doc.data().bwebsite
       }))
     );
   });
 }

 useEffect(() => {
  getTodos();    
}, []) // blank to run only on first time


const[ultinvoice, setUltinvoice] = useState(null)
const ulimoinv = useSelector(state => state.estimates.estimates)

useEffect(() => {
  const rel =  ulimoinv[ulimoinv.length - 1]
  setUltinvoice(rel)
},[ulimoinv])

    
    const [inputfiltro, setInputFiltro] = useState('')
    const [filterPhone, setFilterPhone] = useState(null)

    const searchByPhone = useSelector(state => state.estimates.estimates)
    const  onChangeHandlerInput = (event) => {
        setInputFiltro(event.target.value);   
    }

    const handlerSubmit = () => {
        setFilterPhone(searchByPhone.filter(item => item.Phone === inputfiltro))        
    }
   const print = () => {
      window.print();
    }
    return(
        <>
       <Container>
            <Row md={4} className='mt-2'>
                    <Link to='/account' style={{textDecoration: 'none', marginTop:10}}>
                      <ArrowBackIcon color='primary' />
                    </Link>                    
                    
                   <Autocomplete                      
                      size='small'
                      id="idPhone"
                      options={ searchByPhone}
                      getOptionLabel={(option) => option.Phone}
                      style={{ width: 180 }}
                      renderInput={(params) => <TextField {...params} id="Phone" name="Phone" type='text' label="--Phone Number-- "  variant="standard"  onSelect={onChangeHandlerInput } />}
                    /> 
                  <Button  
                    onClick={handlerSubmit}                              
                    type="button"
                     variant="outlined"
                     color="primary"
                     size="small"
                     startIcon={<SearchIcon />}                  
                    >
                     Search
                    </Button> 
            </Row>
        </Container>   
        {
            filterPhone !== null &&
            filterPhone.map(x => 
                <Container style={{marginTop:'40px'}} key={x.id} >                        
                <Row className='mb-3'>
                    <table className=" table-borderless">
                      <thead>
                        <tr>
                          <th  >
                            <div className='text-center'  >
                                   <Link to='/account'>
                                    {
                                      url != null ?
                                      <img               
                                     style={{width: '200px', height:150}}
                                     src={url}
                                     alt='..'  /> :
                                     <Spinner animation="border" role="status">
                                       <span className="visually-hidden">Loading...</span>
                                     </Spinner>
                                    }
                                   </Link>
                               </div>
                            </th>
                          <th  >
                            <div className="text-left" >
                               <h2 className="text-center">{todos[0] ? todos[0].businessname : ''}</h2>
                                  <p className="text-center">{todos[0] ? todos[0].bstreet : ''}<br/>
                                  {todos[0] ? todos[0].bcity : ''}<br/>{todos[0] ? todos[0].bphone : ''}</p>
                            </div>                         
                          </th>
                          <th  > 
                            <div className='text-center'>
                              <h3 >Estimate No:</h3><br></br>
                              <h3>{x.Idinvoice}</h3>
                            </div>
                          </th>
                          {/* <th ></th> */}
                        </tr>
                      </thead>
                      <tbody>
                        <tr><td></td></tr>
                        <tr><td></td></tr>
                        <tr><td></td></tr>
      
                      </tbody>               
                    </table>
                </Row>
               
               
              <Row>
                  <table className="table  table-sm">
                    {/* <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">First</th>
                        <th scope="col">Last</th>
                        <th scope="col">Handle</th>
                      </tr>
                    </thead> */}
                    <tbody>
                       <tr>
                        <td ><TextField fullWidth InputProps={{ disableUnderline: true }} id="Company" name='Company' type='text' label="Company" variant="standard" readOnly ={true} value={x.Company} /></td>
                        <td><TextField fullWidth  InputProps={{ disableUnderline: true }} id="Attn" name='Attn' type='text' label="Attn" variant="standard" readOnly ={true} value={x.Attn} /> </td>
                        <td ><TextField fullWidth InputProps={{ disableUnderline: true }} id="Phone" name='Phone' type='text'  label="Phone Number" variant="standard" readOnly ={true} value={x.Phone}  /></td>
                      </tr>
                      <tr>                  
                        <td><TextField fullWidth InputProps={{ disableUnderline: true }} id="Estimator" name='Estimator' type='text' label="Estimator" variant="standard" readOnly ={true} value={x.Estimator} /></td>                  
                        <td><TextField fullWidth InputProps={{ disableUnderline: true }} id="ProjectName" name='ProjectName' type='text' label="Project Name" variant="standard" readOnly ={true} value={x.Project} /></td>
                        <td><TextField fullWidth InputProps={{ disableUnderline: true }} format="MM/dd/yyyy" id="Date" name='Date' type='date' label="Date" variant="standard" readOnly ={true} value={x.Date} /></td> 
                      </tr>
                      </tbody>
                      </table>
                      {/* <h6 className='text-center'>Descriptions</h6> */}
                      <table className="table  table-sm ">            
                    <tbody>
                      <tr className='text-center'>
                        <th colSpan="3" >Description</th>
                        <th style={{width:'130px'}} >Quantity</th>
                        <th style={{width:'130px'}} >Price $</th>
                        <th scope="row" style={{width:'130px'}}>Sub Total</th>
                       </tr>
                      <tr >
                        <th scope="row" style={{width:'180px'}}>
                              <select className="custom-select"  id="Select1" readOnly ={true}  value={x.Lines_details[0].Des} >
                                  <option value=""></option>
                                  <option value="Material">Material</option>
                                  <option value="Labor">Labor</option>
                                  <option value="Galvanizing">Galvanizing</option>
                                  <option value="Shipping">Shipping</option>
                                  <option value="Detailing">Detailing</option>
                                  <option value="Printing">Printing</option>
                                  <option value="Painting">Painting</option>
                                  <option value="Material-2">Material - 2</option>
                                  <option value="Material-3">Material - 3</option>
                                  <option value="Material-4">Material - 4</option>
                                  <option value="Accesories">Accesories</option>
                                  <option value="Accesories">Other Charges</option>
                              </select>
                        </th>
                        <td colSpan="2"><TextField multiline fullWidth InputProps={{ disableUnderline: true}}  id="Detail1" name='Detail1' type='text'  variant="standard" readOnly ={true}  value={x.Lines_details[0].Dess} /></td>
                        <td ><TextField  fullWidth InputProps={{ disableUnderline: true }}  inputProps={{min: 0, style: { textAlign: 'center' }}}  id="Quantity1" name='Quantity1' type='number'  variant="standard" readOnly ={true} value={x.Lines_details[0].Qua} /></td>
                        <td ><TextField  fullWidth InputProps={{ disableUnderline: true }}  inputProps={{min: 0, style: { textAlign: 'center' }}}  id="Price1" name='Price1' type='number'  variant="standard" readOnly ={true} value={x.Lines_details[0].Pri} /></td>
                        <td><TextField   fullWidth InputProps={{ disableUnderline: true }}  inputProps={{min: 0, style: { textAlign: 'center' }}}  id="Stotal1" name='Stotal1' type='number'  variant="standard" readOnly ={true} value={((x.Lines_details[0].Qua ?  parseInt(x.Lines_details[0].Qua) * parseInt(x.Lines_details[0].Pri) : 0) ).toFixed(2)} /></td>
                      </tr>
                      <tr>
                        <th scope="row">
                        <select className="custom-select"  id="Select2" readOnly ={true} value={x.Lines_details[1].Des} >
                                  <option value=""></option>
                                  <option value="Material">Material</option>
                                  <option value="Labor">Labor</option>
                                  <option value="Galvanizing">Galvanizing</option>
                                  <option value="Shipping">Shipping</option>
                                  <option value="Detailing">Detailing</option>
                                  <option value="Printing">Printing</option>
                                  <option value="Painting">Painting</option>
                                  <option value="Material-2">Material - 2</option>
                                  <option value="Material-3">Material - 3</option>
                                  <option value="Material-4">Material - 4</option>
                                  <option value="Accesories">Accesories</option>
                                  <option value="Accesories">Other Charges</option>
                              </select>
                        </th>
                        <td colSpan="2"><TextField multiline fullWidth InputProps={{ disableUnderline: true }} id="Detail1" name='Detail1' type='text'  variant="standard" readOnly ={true} value={x.Lines_details[1].Dess} /></td>
                        <td><TextField fullWidth InputProps={{ disableUnderline: true }} inputProps={{min: 0, style: { textAlign: 'center' }}} id="Quantity2" name='Quantity2' type='number'  variant="standard" readOnly ={true}  value={x.Lines_details[1].Qua} /></td>
                        <td><TextField fullWidth InputProps={{ disableUnderline: true }} inputProps={{min: 0, style: { textAlign: 'center' }}} id="Price2" name='Price2' type='number'  variant="standard" readOnly ={true} value={x.Lines_details[1].Pri} /></td>
                        <td><TextField fullWidth InputProps={{ disableUnderline: true }} inputProps={{min: 0, style: { textAlign: 'center' }}} id="Stotal2" name='Stotal2' type='number'  variant="standard" readOnly ={true} value={((x.Lines_details[1].Qua ?  parseInt(x.Lines_details[1].Qua) * parseInt(x.Lines_details[1].Pri) : 0) ).toFixed(2)}  /></td>
                      </tr>
                      <tr>
                        <th scope="row">
                        <select className="custom-select"  id="Select3" readOnly ={true}  value={x.Lines_details[2].Des} >
                                  <option value=""></option>
                                  <option value="Material">Material</option>
                                  <option value="Labor">Labor</option>
                                  <option value="Galvanizing">Galvanizing</option>
                                  <option value="Shipping">Shipping</option>
                                  <option value="Detailing">Detailing</option>
                                  <option value="Printing">Printing</option>
                                  <option value="Painting">Painting</option>
                                  <option value="Material-2">Material - 2</option>
                                  <option value="Material-3">Material - 3</option>
                                  <option value="Material-4">Material - 4</option>
                                  <option value="Accesories">Accesories</option>
                                  <option value="Accesories">Other Charges</option>
                              </select>
                        </th>
                        <td colSpan="2"><TextField multiline fullWidth InputProps={{ disableUnderline: true }} id="Detail3" name='Detail3' type='text'  variant="standard" readOnly ={true} value={x.Lines_details[2].Dess} /> </td>
                        <td><TextField fullWidth InputProps={{ disableUnderline: true }} inputProps={{min: 0, style: { textAlign: 'center' }}}   id="Quantity3" name='Quantity3' type='number'  variant="standard" readOnly ={true}  value={x.Lines_details[2].Qua} /></td>
                        <td><TextField fullWidth InputProps={{ disableUnderline: true }} inputProps={{min: 0, style: { textAlign: 'center' }}}   id="Price3" name='Price3' type='number'  variant="standard" readOnly ={true} value={x.Lines_details[2].Pri} /></td>
                        <td><TextField fullWidth InputProps={{ disableUnderline: true }} inputProps={{min: 0, style: { textAlign: 'center' }}}    id="Stotal3" name='Stotal3' type='number'  variant="standard" readOnly ={true} value={((x.Lines_details[2].Qua ?  parseInt(x.Lines_details[2].Qua) * parseInt(x.Lines_details[2].Pri) : 0) ).toFixed(2)} /></td>
                      </tr>                
                      <tr>
                        <th scope="row">
                        <select className="custom-select"  id="Select4"  readOnly ={true} value={x.Lines_details[3].Des} >
                                  <option value=""></option>
                                  <option value="Material">Material</option>
                                  <option value="Labor">Labor</option>
                                  <option value="Galvanizing">Galvanizing</option>
                                  <option value="Shipping">Shipping</option>
                                  <option value="Detailing">Detailing</option>
                                  <option value="Printing">Printing</option>
                                  <option value="Painting">Painting</option>
                                  <option value="Material-2">Material - 2</option>
                                  <option value="Material-3">Material - 3</option>
                                  <option value="Material-4">Material - 4</option>
                                  <option value="Accesories">Accesories</option>
                                  <option value="Accesories">Other Charges</option>
                              </select>
                        </th>
                        <td colSpan="2"><TextField multiline fullWidth InputProps={{ disableUnderline: true }} id="Detail4" name='Detail4' type='text'  variant="standard" readOnly ={true} value={x.Lines_details[3].Dess} /></td>
                        <td><TextField fullWidth InputProps={{ disableUnderline: true }}  inputProps={{min: 0, style: { textAlign: 'center' }}} id="Quantity4" name='Quantity4' type='number'  variant="standard" readOnly ={true}  value={x.Lines_details[3].Qua} /></td>
                        <td><TextField fullWidth InputProps={{ disableUnderline: true }}  inputProps={{min: 0, style: { textAlign: 'center' }}} id="Price4" name='Price4' type='number'  variant="standard" readOnly ={true} value={x.Lines_details[3].Pri} /></td>
                        <td><TextField fullWidth InputProps={{ disableUnderline: true }}  inputProps={{min: 0, style: { textAlign: 'center' }}} id="Stotal4" name='Stotal4' type='number'  variant="standard" readOnly ={true} value={((x.Lines_details[3].Qua ?  parseInt(x.Lines_details[3].Qua) * parseInt(x.Lines_details[3].Pri) : 0) ).toFixed(2)}  /></td>
                      </tr>
                      <tr>
                        <th scope="row">
                        <select className="custom-select"  id="Select5" readOnly ={true}  value={x.Lines_details[4].Des} >
                                  <option value=""></option>
                                  <option value="Material">Material</option>
                                  <option value="Labor">Labor</option>
                                  <option value="Galvanizing">Galvanizing</option>
                                  <option value="Shipping">Shipping</option>
                                  <option value="Detailing">Detailing</option>
                                  <option value="Printing">Printing</option>
                                  <option value="Painting">Painting</option>
                                  <option value="Material-2">Material - 2</option>
                                  <option value="Material-3">Material - 3</option>
                                  <option value="Material-4">Material - 4</option>
                                  <option value="Accesories">Accesories</option>
                                  <option value="Accesories">Other Charges</option>
                              </select>
                        </th>
                        <td colSpan="2"><TextField multiline fullWidth InputProps={{ disableUnderline: true }} id="Detail5" name='Detail5' type='text'  variant="standard" readOnly ={true}  value={x.Lines_details[4].Dess} /></td>
                        <td><TextField fullWidth InputProps={{ disableUnderline: true }} inputProps={{min: 0, style: { textAlign: 'center' }}} id="Quantity5" name='Quantity5' type='number'  variant="standard" readOnly ={true} value={x.Lines_details[4].Qua} /></td>
                        <td><TextField fullWidth InputProps={{ disableUnderline: true }} inputProps={{min: 0, style: { textAlign: 'center' }}} id="Price5" name='Price5' type='number'  variant="standard" readOnly ={true} value={x.Lines_details[4].Pri} /></td>
                        <td><TextField fullWidth InputProps={{ disableUnderline: true }} inputProps={{min: 0, style: { textAlign: 'center' }}} id="Stotal5" name='Stotal5' type='number'  variant="standard" readOnly ={true} value={((x.Lines_details[4].Qua ?  parseInt(x.Lines_details[4].Qua) * parseInt(x.Lines_details[4].Pri) : 0) ).toFixed(2)}  /></td>
                      </tr>
                      <tr>
                        <th scope="row">
                        <select className="custom-select"  id="Select6" readOnly ={true}  value={x.Lines_details[5].Des} >
                                  <option value=""></option>
                                  <option value="Material">Material</option>
                                  <option value="Labor">Labor</option>
                                  <option value="Galvanizing">Galvanizing</option>
                                  <option value="Shipping">Shipping</option>
                                  <option value="Detailing">Detailing</option>
                                  <option value="Printing">Printing</option>
                                  <option value="Painting">Painting</option>
                                  <option value="Material-2">Material - 2</option>
                                  <option value="Material-3">Material - 3</option>
                                  <option value="Material-4">Material - 4</option>
                                  <option value="Accesories">Accesories</option>
                                  <option value="Accesories">Other Charges</option>
                              </select>
                        </th>
                        <td colSpan="2"><TextField multiline fullWidth InputProps={{ disableUnderline: true }} id="Detail6" name='Detail6' type='text'  variant="standard" readOnly ={true}  value={x.Lines_details[5].Dess} /></td>
                        <td><TextField fullWidth InputProps={{ disableUnderline: true }} inputProps={{min: 0, style: { textAlign: 'center' }}} id="Quantity6" name='Quantity6' type='number'  variant="standard" readOnly ={true}  value={x.Lines_details[5].Qua} /></td>
                        <td><TextField fullWidth InputProps={{ disableUnderline: true }} inputProps={{min: 0, style: { textAlign: 'center' }}} id="Price6" name='Price6' type='number'  variant="standard" readOnly ={true} value={x.Lines_details[5].Pri} /></td>
                        <td><TextField fullWidth InputProps={{ disableUnderline: true }} inputProps={{min: 0, style: { textAlign: 'center' }}} id="Stotal6" name='Stotal6' type='number'  variant="standard"  readOnly ={true} value={((x.Lines_details[5].Qua ?  parseInt(x.Lines_details[5].Qua) * parseInt(x.Lines_details[5].Pri) : 0) ).toFixed(2)} /></td>
                      </tr>
                      <tr>
                        <th scope="row"> 
                        <select className="custom-select"  id="Select7" readOnly ={true}  value={x.Lines_details[6].Des} >
                                  <option value=""></option>
                                  <option value="Material">Material</option>
                                  <option value="Labor">Labor</option>
                                  <option value="Galvanizing">Galvanizing</option>
                                  <option value="Shipping">Shipping</option>
                                  <option value="Detailing">Detailing</option>
                                  <option value="Printing">Printing</option>
                                  <option value="Painting">Painting</option>
                                  <option value="Material-2">Material - 2</option>
                                  <option value="Material-3">Material - 3</option>
                                  <option value="Material-4">Material - 4</option>
                                  <option value="Accesories">Accesories</option>
                                  <option value="Accesories">Other Charges</option>
                              </select>
                        </th>
                        <td colSpan="2"><TextField multiline fullWidth InputProps={{ disableUnderline: true }} id="Other-text" name='Other-text' type='text' label="" variant="standard" readOnly ={true} value={x.Lines_details[6].Dess} /></td>
                        <td><TextField fullWidth InputProps={{ disableUnderline: true }} inputProps={{min: 0, style: { textAlign: 'center' }}} id="Other" name='Other' type='number' label="" variant="standard" readOnly ={true} value={x.Lines_details[6].Qua} /></td>
                        <td><TextField fullWidth InputProps={{ disableUnderline: true }} inputProps={{min: 0, style: { textAlign: 'center' }}} id="Other" name='Other' type='number' label="" variant="standard" readOnly ={true} value={x.Lines_details[6].Pri} /></td>
                        <td><TextField fullWidth InputProps={{ disableUnderline: true }} inputProps={{min: 0, style: { textAlign: 'center' }}} id="Other" name='Other' type='number' label="" variant="standard" readOnly ={true} value={((x.Lines_details[6].Qua ?  parseInt(x.Lines_details[6].Qua) * parseInt(x.Lines_details[6].Pri) : 0) ).toFixed(2)} /></td>
                      </tr>
                      <tr>
                        <th scope="row" colSpan="5" className='text-center'><h5>TOTAL $</h5></th>
                        <th className='text-center'> 
                        
                          {
                          ((x.Lines_details[0].Qua ?  parseInt(x.Lines_details[0].Qua) * parseInt(x.Lines_details[0].Pri) : 0) +
                          ( x.Lines_details[1].Qua ?  parseInt(x.Lines_details[1].Qua) * parseInt(x.Lines_details[1].Pri) : 0) + 
                          ( x.Lines_details[2].Qua ?  parseInt(x.Lines_details[2].Qua) * parseInt(x.Lines_details[2].Pri) : 0) + 
                          ( x.Lines_details[3].Qua ?  parseInt(x.Lines_details[3].Qua) * parseInt(x.Lines_details[3].Pri) : 0) + 
                          ( x.Lines_details[4].Qua ?  parseInt(x.Lines_details[4].Qua) * parseInt(x.Lines_details[4].Pri) : 0) +
                          ( x.Lines_details[5].Qua ?  parseInt(x.Lines_details[5].Qua) * parseInt(x.Lines_details[5].Pri) : 0) +
                          ( x.Lines_details[6].Qua ?  parseInt(x.Lines_details[6].Qua) * parseInt(x.Lines_details[6].Pri) : 0)).toFixed(2)
                          }
                                          
                        </th>                  
                      </tr>
                      <tr>
                        <td colSpan="6">
                        <h6>Notes:</h6>
                        <textarea  
                          style={{ borderColor:'white', width:'100%',  height:'100px', resize:'vertical'}}
                           id="Notes" 
                           name="Notes" 
                           type='text' 
                           readOnly ={true}
                           value={x.Notes}
                        />  
                        </td>
                      </tr>        
                    </tbody>
                  </table>
              </Row>
               <Row>
                   <Col md={4} className='text-center' >
                       <span><strong>{todos[0] ? todos[0].bwebsite : ''}</strong></span>
                   </Col>
                   <Col md={4} className='text-center'>
                       <h5 ><strong>Thanks for choosing us.</strong></h5>
                   </Col>
                   <Col md={4} >
                   <Row>
                       <Col>
                          {/* <Button                                
                               type="button"
                                variant="outlined"
                                color="primary"
                                size="small"
                                className="primary"
                                startIcon={<SaveIcon />}                
                               >
                                Save
                           </Button> */}
                       </Col>
                       <Col>
                           <Button    
                                  onClick={print}  
                                   variant="outlined"
                                   color="secondary"
                                   size="small"
                                   className="primary"
                                   startIcon={<PrintIcon />}                  
                                  >
                                   Print
                              </Button>
                       </Col>
      
                     </Row>
                   </Col>
               </Row>
             
            </Container>
            )}
      </>
)}
export default SearchEstimate