import React, {useState, useEffect} from 'react'
import { Container, Row, Col, Spinner  } from 'react-bootstrap'
import Axios from 'axios'
import {selectUser } from '../auth/users/userSlice'
import { useSelector } from 'react-redux'
import firebase from "firebase";
import { db } from '../auth/firebase'
import { Link } from 'react-router-dom';
import './InvoiceTowing.css'
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import PrintIcon from '@mui/icons-material/Print';
import SaveIcon from '@material-ui/icons/Save';
import front1 from '../assets/image/front.png'
import rear1 from '../assets/image/rear.png'
import right1 from '../assets/image/right.png'
import left1 from '../assets/image/left.png'

 
const  InvoiceTowing = (props) => { 


  const user = useSelector(selectUser)
  const [url, setUrl] = useState(null);
  const [todos, setTodos] = useState([]);

  const getProfileImage = () => {
    // firebase.storage().ref(`users/${user.uid}/cropped-GottaWebDesign-02-6-scaled-345x219-1-160x93.jpg`).getDownloadURL().then(imgUrl => {setUrl(imgUrl)})
    firebase
    .storage()
    .ref('users')
    .child(user.uid + "/logo.png")
    .getDownloadURL()
    .then((imgUrl) => {
      setUrl(imgUrl);
    });
  }

  useEffect(() => {
    setTimeout(() => {
      getProfileImage();
    }, 1000);
  }, [])



 // Get Todos
 const  getTodos = () => {
   db.collection(user.uid).onSnapshot(function(querySnapshot) {
     setTodos(
       querySnapshot.docs.map((doc) => ({
         id: doc.id,
         bcity: doc.data().bcity,
         bemail: doc.data().bemail,
         bstreet: doc.data().bstreet,
         businessname: doc.data().businessname,
         bphone: doc.data().bphone,
         bwebsite: doc.data().bwebsite
       }))
     );
   });
 }

 useEffect(() => {
  getTodos();    
}, []) // blank to run only on first time


const[ultinvoice, setUltinvoice] = useState(null)
const ulimoinv = useSelector(state => state.invoices.invoices)

useEffect(() => {
  const rel =  ulimoinv[ulimoinv.length - 1]
  setUltinvoice(rel)
},[ulimoinv])


// console.log(ultinvoice.Idinvoice)



  const [idinvoice, setidinvoice] = useState('')
  const [name,      setname] = useState('')
  const [phone,     setphone] = useState('')
  const [address,   setaddress] = useState('')
  const [city,      setcity] = useState('')
  const [zip,       setzip] = useState('')
  const [keyss,     setkeyss] = useState('')
  const [driver,    setdriver] = useState('') 
  const [date1,      setdate1] = useState('')
  
  const [year,       setyear] = useState('')
  const [make,       setmake] = useState('')
  const [model,      setmodel] = useState('')
  const [vin,      setvin] = useState('')
  const [color,      setcolor] = useState('')
  const [license,    setlicense] = useState('')
  const [state,      setstate] = useState('')

  const [towedf,    settowedf] = useState('')
  const [towedt,    settowedt] = useState('')
  const [hoop,      sethoop] = useState('')
  const [mileage,   setmileage] = useState('')
  const [dollies,   setdollies] = useState('')
  const [winch,     setwinch] = useState('')
  const [lock,      setlock] = useState('')
  const [gas,       setgas] = useState('')
  const [clean,     setclean] = useState('')

  const [dispatch,     setdispatch] = useState('')
  const [gate,     setgate] = useState('')
  const [secondtow,     setsecondtow] = useState('')

  const [store,     setstore] = useState('')
  const [other,     setother] = useState('')
  const [total,     settotal] = useState('')
  const [front,     setfront] = useState('')
  const [rear,      setrear] = useState('')
  const [right,     setright] = useState('')
  const [left,      setleft] = useState('')
  const [date2,     setdate2] = useState('')
  const [payment,   setpayment] = useState('')

  const [hooptext,      sethooptext] = useState('')
  const [mileagetext,   setmileagetext] = useState('')
  const [dolliestext,   setdolliestext] = useState('')
  const [winchtext,     setwinchtext] = useState('')
  const [locktext,      setlocktext] = useState('')
  const [gastext,       setgastext] = useState('')
  const [cleantext,     setcleantext] = useState('')
  const [dispatchtext,     setdispatchtext] = useState('')
  const [gatetext,     setgatetext] = useState('')
  const [storetext,     setstoretext] = useState('')
  const [othertext,     setothertext] = useState('')
  const [secondtowtext,     setsecondtowtext] = useState('')


    
    //  function myChangeHandlerbalance() { 
       
    //   setbalance(
        
    //       ((((pri1 ?  parseInt(pri1) * parseInt(qua1) : 0) ) + 
    //         ((pri2 ?  parseInt(pri2) * parseInt(qua2) : 0) ) +
    //         ((pri3 ?  parseInt(pri3) * parseInt(qua3) : 0) ) + 
    //         ((pri4 ?  parseInt(pri4) * parseInt(qua4) : 0) ) + 
    //         ((pri5 ?  parseInt(pri5) * parseInt(qua5) : 0) ) + 
    //         ((pri6 ?  parseInt(pri6) * parseInt(qua6) : 0) )) - (amo ? parseInt(amo) : 0)).toFixed(2)
    //   )
    
    // }
     

     function myChangeHandlersetidinvoice(event) { setidinvoice(event.target.value)}
     function myChangeHandlersetname(event) { setname(event.target.value)}     
     function myChangeHandlersetphone(event) { setphone(event.target.value)}      
     function myChangeHandlersetaddress(event) { setaddress(event.target.value)}
     function myChangeHandlersetcity(event) { setcity(event.target.value)}
     function myChangeHandlersetzip(event) { setzip(event.target.value)}    
     function myChangeHandlersetkeyss(event) { setkeyss(event.target.value)}
     function myChangeHandlersetdriver(event) { setdriver(event.target.value)}
     function myChangeHandlersetdate1(event) {  setdate1(event.target.value)} 

     function myChangeHandlersetyear(event) {  setyear(event.target.value)} 
     function myChangeHandlersetmake(event) {  setmake(event.target.value)} 
     function myChangeHandlersetmodel(event) {  setmodel(event.target.value)} 
     function myChangeHandlersetvin(event) {  setvin(event.target.value)} 
     function myChangeHandlersetcolor(event) {  setcolor(event.target.value)} 
     function myChangeHandlersetlicense(event) {  setlicense(event.target.value)} 
     function myChangeHandlersetstate(event) {  setstate(event.target.value)} 

     function myChangeHandlersettowedf(event) { settowedf(event.target.value)}
     function myChangeHandlersettowedt(event) { settowedt(event.target.value)}
     function myChangeHandlersethoop(event) { sethoop(event.target.value)} 
     function myChangeHandlersetmileage(event) { setmileage(event.target.value)}
     function myChangeHandlersetdollies(event) { setdollies(event.target.value)}
     function myChangeHandlersetwinch(event) { setwinch(event.target.value)}  
     function myChangeHandlersetlock(event) { setlock(event.target.value)}
     function myChangeHandlersetgas(event) { setgas(event.target.value)}
     function myChangeHandlersetclean(event) { setclean(event.target.value)}  

     function myChangeHandlersetdispatch(event) { setdispatch(event.target.value)}  
     function myChangeHandlersetgate(event) { setgate(event.target.value)}  
     function myChangeHandlersetsecondtow(event) { setsecondtow(event.target.value)}  

     function myChangeHandlersetstore(event)  { setstore(event.target.value)}
     function myChangeHandlersetother (event)  { setother(event.target.value)}
     function myChangeHandlersettotal(event)  { settotal(event.target.value)}
     function myChangeHandlersetfront(event)  { setfront(event.target.value)}
     function myChangeHandlersetrear(event)  { setrear(event.target.value)}
     function myChangeHandlersetright(event)  { setright(event.target.value)}
     function myChangeHandlersetleft(event)  { setleft(event.target.value)}
     function myChangeHandlersetdate2(event)  { setdate2(event.target.value)}
     function myChangeHandlersetpayment(event)  { setpayment(event.target.value)}
     function myChangeHandlersethooptext(event)  { sethooptext(event.target.value)}
     function myChangeHandlersetmileagetext(event)  { setmileagetext(event.target.value)}
     function myChangeHandlersetdolliestext(event)  { setdolliestext(event.target.value)}
     function myChangeHandlersetwinchtext(event)  { setwinchtext(event.target.value)}
     function myChangeHandlersetlocktext(event)  { setlocktext(event.target.value)}
     function myChangeHandlersetgastext(event)  { setgastext(event.target.value)}
     function myChangeHandlersetcleantext(event)  { setcleantext(event.target.value)}

     function myChangeHandlersetdispatchtext(event) { setdispatchtext(event.target.value)}  
     function myChangeHandlersetgatetext(event) { setgatetext(event.target.value)}  
     function myChangeHandlersetsecondtowtext(event) { setsecondtowtext(event.target.value)}  

     function myChangeHandlersetstoretext(event)  { setstoretext(event.target.value)}
     function myChangeHandlersetothertext(event)  { setothertext(event.target.value)}

     function print(){
         window.print()
       }


       const authentication = () => {          
 
       const data = {

          "Idinvoice": ultinvoice != null ? parseInt(ultinvoice.Idinvoice) + 1 : '',
          "Name": name,    
          "Phone": phone,   
          "Address": address, 
          "City": city,    
          "Zip": zip,     
          "Keyss": keyss,   
          "Driver": driver,  
          "Date1": date1,   
          "Year": year,   
          "Make": make,   
          "Model": model,   
          "Vin": vin,   
          "Color": color,   
          "License": license,   
          "State": state,   
          "Towedf": towedf,  
          "Towedt": towedt,  
          "Hoop": hoop,    
          "Mileage": mileage, 
          "Dollies": dollies, 
          "Winch": winch,   
          "Lock": lock,    
          "Gas": gas,     
          "Clean": clean,   
          "Dispatch": dispatch,   
          "Gate": gate,   
          "Secondtow": secondtow,   
          "Store": store,   
          "Other": other,   
          "Total": ((((hoop ? parseFloat(hoop) : 0) ) + 
                        ((mileage ?  parseFloat(mileage) : 0) ) +
                        ((dollies ?  parseFloat(dollies) : 0) ) + 
                        ((winch ?  parseFloat(winch) : 0) ) + 
                        ((lock ?  parseFloat(lock) : 0) ) + 
                        ((gas ?  parseFloat(gas) : 0) )) +
                        ((clean ?  parseFloat(clean) : 0) ) + 
                        ((dispatch ?  parseFloat(dispatch) : 0) ) + 
                        ((gate ?  parseFloat(gate) : 0) ) + 
                        ((secondtow ?  parseFloat(secondtow) : 0) ) + 
                        ((store ?  parseFloat(store) : 0) ) + 
                        ((other ?  parseFloat(other) : 0) ) ).toFixed(2),  
          "Front": front,   
          "Rear": rear,    
          "Right": right,   
          "Left": left,    
          "Date2": date2,   
          "Payment": payment,           
          "Hooptext": hooptext,  
          "Mileagetext": mileagetext,
          "Dolliestext": dolliestext,
          "Winchtext": winchtext,
          "Locktext": locktext,  
          "Gastext": gastext,  
          "Cleantext": cleantext, 
          "Dispatchtext": dispatchtext, 
          "Gatetext": gatetext, 
          "Secondtowtext": secondtowtext, 
          "Storetext": storetext,
          "Othertext": othertext
        
      }  

      Axios.post(`${process.env.REACT_APP_BASE_URL}${user.uid}/${process.env.REACT_APP_CREATE_INVOICE}/${user.uid}/data.json`, data)
        .then(r => {
          localStorage.setItem('token', r.data.token)
          window.location = "/"
        }).then(() => alert('The Invoice has been succesfully saved'))
        .catch(e => {
          alert('error al iniciar sesion')
        } )
      // console.log(data)
      }

     
    return (

        <Container style={{marginTop:'40px'}} >                        
          <Row className='mb-3'>
              <table className=" table-borderless">
                <thead>
                  <tr>
                    <th  >
                      <div className='text-center'  >
                             <Link to='/account'>
                              {
                                url != null ?
                                <img               
                               style={{width: '200px', height:150}}
                               src={url}
                               alt='..'  /> :
                               <Spinner animation="border" role="status">
                                 <span className="visually-hidden">Loading...</span>
                               </Spinner>
                              }
                             </Link>
                         </div>
                      </th>
                    <th  >
                      <div className="text-left" >
                         <h2 className="text-center">{todos[0] ? todos[0].businessname : ''}</h2>
                            <p className="text-center">{todos[0] ? todos[0].bstreet : ''}<br/>
                            {todos[0] ? todos[0].bcity : ''}<br/>{todos[0] ? todos[0].bphone : ''}</p>
                      </div>                         
                    </th>
                    <th  > 
                      <div className='text-left'>
                        <h3 >Invoice No:</h3><br></br>
                        <h3>{ultinvoice != null ? parseInt(ultinvoice.Idinvoice) + 1 : ''}</h3>
                      </div>
                    </th>
                    {/* <th ></th> */}
                  </tr>
                </thead>
                <tbody>
                  <tr><td></td></tr>
                  <tr><td></td></tr>
                  <tr><td></td></tr>

                </tbody>               
              </table>
          </Row>
         
         
        <Row>
            <table className="table  table-sm">
              {/* <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">First</th>
                  <th scope="col">Last</th>
                  <th scope="col">Handle</th>
                </tr>
              </thead> */}
              <tbody>
                 <tr>
                  <td > <TextField fullWidth id="Name" name='Name' type='text' label="Full Name" variant="standard" onChange={myChangeHandlersetname} /></td>
                  <td ><TextField fullWidth  id="Phone" name='Phone' type='text'  label="Phone Number" variant="standard" onChange={myChangeHandlersetphone}  /></td>
                  <td> <TextField fullWidth  id="Address" name='Address' type='text' label="Address" variant="standard" onChange={myChangeHandlersetaddress} /></td>
                  <td><TextField fullWidth  id="City" name='City' type='text' label="City / State" variant="standard" onChange={myChangeHandlersetcity} /> </td>
                  <td><TextField fullWidth  id="Zip" name='Zip' type='number'  label="Zip-Code" variant="standard"  onChange={myChangeHandlersetzip} /></td>
                </tr>
                <tr>                  
                  <td><TextField fullWidth id="Year" name='Year' type='text' label="Year" variant="standard" onChange={myChangeHandlersetyear} /></td>
                  <td><TextField fullWidth id="Make" name='Make' type='text' label="Make" variant="standard" onChange={myChangeHandlersetmake} /></td> 
                  <td><TextField fullWidth id="Model" name='Model' type='text' label="Model" variant="standard" onChange={myChangeHandlersetmodel} /></td>
                  <td><TextField fullWidth id="Vin" name='Vin' type='text' label="VIN #" variant="standard" onChange={myChangeHandlersetvin} /></td>   
                  <td><TextField fullWidth id="Colors" name='Colors' type='text' label="Color" variant="standard" onChange={myChangeHandlersetcolor} /></td>                      
                </tr>
                <tr>
                  <td><TextField fullWidth id="Lic" name='Lic' type='text' label="License Plate Number" variant="standard" onChange={myChangeHandlersetlicense} /></td>
                  <td><TextField fullWidth id="State" name='State' type='text' label="State" variant="standard" onChange={myChangeHandlersetstate} /></td>
                  <td><TextField fullWidth  id="Keyss" name='Keyss' type='text'  label="Keys" variant="standard"  onChange={myChangeHandlersetkeyss} /></td>
                  <td><TextField fullWidth  id="Driver" name='Driver' type='text'  label="Driver" variant="standard"  onChange={myChangeHandlersetdriver} /></td>
                  <td ><TextField fullWidth  id="Date1" name='Date1' type='datetime-local' label='Date'  variant="standard" onChange={myChangeHandlersetdate1}  /></td> 
                </tr> 
                <tr>
                  <td colSpan={2}> <TextField fullWidth id="Towedf" name='Towedf' type='text' label="Towed From" variant="standard" onChange={myChangeHandlersettowedf} /></td>
                  <td colSpan={2}><TextField fullWidth id="Towedt" name='Towedt' type='text' label="Truck Towed To" variant="standard" onChange={myChangeHandlersettowedt} /></td>
                  <td></td>
                </tr>
                </tbody>
                </table>
                {/* <h6 className='text-center'>Descriptions</h6> */}
                <table className="table  table-sm ">            
              <tbody>
                <tr>
                  <th scope="row">Hook Up</th>
                  <td colSpan="2"><TextField multiline fullWidth  id="Hoop-text" name='Hoop-text' type='text'  variant="standard"  onChange={myChangeHandlersethooptext} /></td>
                  <td><TextField fullWidth InputProps={{ disableUnderline: true }}    id="Hoop" name='Hoop' type='number'  variant="standard" onChange={myChangeHandlersethoop} /></td>
                </tr>
                <tr>
                  <th scope="row">Mileage</th>
                  <td colSpan="2"><TextField multiline fullWidth id="Mileage-text" name='Mileage-text' type='text'  variant="standard"  onChange={myChangeHandlersetmileagetext} /></td>
                  <td><TextField fullWidth InputProps={{ disableUnderline: true }} id="Mileage" name='Mileage' type='number'  variant="standard" onChange={myChangeHandlersetmileage} /></td>
                </tr>
                <tr>
                  <th scope="row">Dollies</th>
                  <td colSpan="2"><TextField multiline fullWidth id="Dollies-text" name='Dollies-text' type='text'  variant="standard" onChange={myChangeHandlersetdolliestext} /> </td>
                  <td><TextField fullWidth InputProps={{ disableUnderline: true }} id="Dollies" name='Dollies' type='number'  variant="standard" onChange={myChangeHandlersetdollies} /></td>
                </tr>                
                <tr>
                  <th scope="row">Winch</th>
                  <td colSpan="2"><TextField multiline fullWidth id="Winch-text" name='Winch-text' type='text'  variant="standard" onChange={myChangeHandlersetwinchtext} /></td>
                  <td><TextField fullWidth InputProps={{ disableUnderline: true }} id="Winch" name='Winch' type='number'  variant="standard" onChange={myChangeHandlersetwinch} /></td>
                </tr>
                <tr>
                  <th scope="row">Lock outs</th>
                  <td colSpan="2"><TextField multiline fullWidth id="Lock-text" name='Lock-text' type='text'  variant="standard"  onChange={myChangeHandlersetlocktext} /></td>
                  <td><TextField fullWidth InputProps={{ disableUnderline: true }} id="Lock" name='Lock' type='number'  variant="standard" onChange={myChangeHandlersetlock} /></td>
                </tr>
                <tr>
                  <th scope="row">Gas delivery</th>
                  <td colSpan="2"><TextField multiline fullWidth id="Gas-text" name='Gas-text' type='text'  variant="standard"  onChange={myChangeHandlersetgastext} /></td>
                  <td><TextField fullWidth InputProps={{ disableUnderline: true }} id="Gas" name='Gas' type='number'  variant="standard" onChange={myChangeHandlersetgas} /></td>
                </tr>
                <tr> 
                  <th scope="row">Clean Up</th>
                  <td colSpan="2"><TextField multiline fullWidth id="Clean-text" name='Clean-text' type='number'  variant="standard" onChange={myChangeHandlersetcleantext} /></td>
                  <td><TextField fullWidth InputProps={{ disableUnderline: true }} id="Clean" name='Clean' type='number'  variant="standard" onChange={myChangeHandlersetclean} /></td>
                </tr>
                <tr> 
                  <th scope="row">Dispatch</th>
                  <td colSpan="2"><TextField multiline fullWidth id="Dispatch-text" name='Dispatch-text' type='number'  variant="standard" onChange={myChangeHandlersetdispatchtext} /></td>
                  <td><TextField fullWidth InputProps={{ disableUnderline: true }} id="Dispatch" name='Dispatch' type='number'  variant="standard" onChange={myChangeHandlersetdispatch} /></td>
                </tr>
                <tr> 
                  <th scope="row">Gate</th>
                  <td colSpan="2"><TextField multiline fullWidth id="Gate-text" name='Gate-text' type='number'  variant="standard" onChange={myChangeHandlersetgatetext} /></td>
                  <td><TextField fullWidth InputProps={{ disableUnderline: true }} id="Gate" name='Gate' type='number'  variant="standard" onChange={myChangeHandlersetgate} /></td>
                </tr>
                <tr> 
                  <th scope="row">2nd Tow</th>
                  <td colSpan="2"><TextField multiline fullWidth id="Secondtown-text" name='Secondtown-text' type='number'  variant="standard" onChange={myChangeHandlersetsecondtowtext} /></td>
                  <td><TextField fullWidth InputProps={{ disableUnderline: true }} id="Secondtow" name='Secondtow' type='number'  variant="standard" onChange={myChangeHandlersetsecondtow} /></td>
                </tr>
                <tr>
                  <th scope="row">Stored Days</th>
                  <td colSpan="2">
                  <select className="custom-select"  id="Store-text"   onChange={myChangeHandlersetstoretext}  >
                            <option value=""></option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                            <option value="22">22</option>
                            <option value="23">23</option>
                            <option value="24">24</option>
                            <option value="25">25</option>
                            <option value="26">26</option>
                            <option value="27">27</option>
                            <option value="28">28</option>
                            <option value="29">29</option>
                            <option value="30">30</option>
                            <option value="31">31</option>
                            <option value="32">32</option>
                            <option value="33">33</option>
                            <option value="34">34</option>
                            <option value="35">35</option>
                            <option value="36">36</option>
                            <option value="37">37</option>
                            <option value="38">38</option>
                            <option value="39">39</option>
                            <option value="40">40</option>
                            <option value="41">41</option>
                            <option value="42">42</option>
                            <option value="43">43</option>
                            <option value="44">44</option>
                            <option value="45">45</option>
                            <option value="46">46</option>
                            <option value="47">47</option>
                            <option value="48">48</option>
                            <option value="49">49</option>
                            <option value="50">50</option>
                            <option value="51">51</option>
                            <option value="52">52</option>
                            <option value="53">53</option>
                            <option value="54">54</option>
                            <option value="55">55</option>
                            <option value="56">56</option>
                            <option value="57">57</option>
                            <option value="58">58</option>
                            <option value="59">59</option>
                            <option value="60">60</option>
                            <option value="61">61</option>
                            <option value="62">62</option>
                            <option value="63">63</option>
                            <option value="64">64</option>
                            <option value="65">65</option>
                            <option value="66">66</option>
                            <option value="67">67</option>
                            <option value="68">68</option>
                            <option value="69">69</option>
                            <option value="70">70</option>
                            <option value="71">71</option>
                            <option value="72">72</option>
                            <option value="73">73</option>
                            <option value="74">74</option>
                            <option value="75">75</option>
                            <option value="76">76</option>
                            <option value="77">77</option>
                            <option value="78">78</option>
                            <option value="79">79</option>
                            <option value="80">80</option>
                            <option value="81">81</option>
                            <option value="82">82</option>
                            <option value="83">83</option>
                            <option value="84">84</option>
                            <option value="85">85</option>
                            <option value="86">86</option>
                            <option value="87">87</option>
                            <option value="88">88</option>
                            <option value="89">89</option>
                            <option value="90">90</option>
                            <option value="91">91</option>
                            <option value="92">92</option>
                            <option value="93">93</option>
                            <option value="94">94</option>
                            <option value="95">95</option>
                            <option value="96">96</option>
                            <option value="97">97</option>
                            <option value="98">98</option>
                            <option value="99">99</option>
                            <option value="100">100</option>
                            <option value="101">101</option>
                            <option value="102">102</option>
                            <option value="103">103</option>
                            <option value="104">104</option>
                            <option value="105">105</option>
                            <option value="106">106</option>
                            <option value="107">107</option>
                            <option value="108">108</option>
                            <option value="109">109</option>
                            <option value="110">110</option>
                            <option value="111">111</option>
                            <option value="112">112</option>
                            <option value="113">113</option>
                            <option value="114">114</option>
                            <option value="115">115</option>
                            <option value="116">116</option>
                            <option value="117">117</option>
                            <option value="118">118</option>
                            <option value="119">119</option>
                            <option value="120">120</option>
                            <option value="121">121</option>
                            <option value="122">122</option>
                            <option value="123">123</option>
                            <option value="124">124</option>
                            <option value="125">125</option>
                            <option value="126">126</option>
                            <option value="127">127</option>
                            <option value="128">128</option>
                            <option value="129">129</option>
                            <option value="129">130</option>
                        </select>
                  </td>
                  <td><TextField fullWidth InputProps={{ disableUnderline: true }} id="Store" name='Store' type='number'  variant="standard" onChange={myChangeHandlersetstore} /></td>
                </tr>
                <tr>
                  <th scope="row">Other Charges</th>
                  <td colSpan="2"><TextField multiline fullWidth id="Other-text" name='Other-text' type='text' label="" variant="standard" onChange={myChangeHandlersetothertext} /></td>
                  <td><TextField fullWidth InputProps={{ disableUnderline: true }} id="Other" name='Other' type='number' label="" variant="standard" onChange={myChangeHandlersetother} /></td>
                </tr>
                <tr>
                  <th scope="row" colSpan="3" className='text-center'>Total</th>
                  <th> 
                    {
                     ` $ ${
                      ((((hoop ? parseFloat(hoop) : 0) ) + 
                      ((mileage ?  parseFloat(mileage) : 0) ) +
                      ((dollies ?  parseFloat(dollies) : 0) ) + 
                      ((winch ?  parseFloat(winch) : 0) ) + 
                      ((lock ?  parseFloat(lock) : 0) ) + 
                      ((gas ?  parseFloat(gas) : 0) )) +
                      ((clean ?  parseFloat(clean) : 0) ) + 
                      ((dispatch ?  parseFloat(dispatch) : 0) ) + 
                      ((gate ?  parseFloat(gate) : 0) ) + 
                      ((secondtow ?  parseFloat(secondtow) : 0) ) + 
                      ((store ?  parseFloat(store) : 0) ) + 
                      ((other ?  parseFloat(other) : 0) ) ).toFixed(2)
                     }`

                    }
                    {/* <TextField fullWidth id="Total" name='Total' type='text' label="Total" variant="standard" onChange={myChangeHandlersettotal} /> */}
                  </th>                  
                </tr>
                <tr>
                  <th scope="row" colSpan="5" className='text-left'>{`I authorized ${todos[0] ? todos[0].businessname : ''} to tow the above listed vehicle. As per Ors. 98.805 - 98.918`}</th>
                </tr>
                <tr>
                  <th scope="row" colSpan="3" className='text-left'>Signature:</th>
                  <td><TextField fullWidth  id="SignDate" name='SignDate' type='datetime-local'  variant="standard" /></td>
                </tr>
                <tr>
                  <th scope="row" colSpan="5" className='text-left'>{`I, the  undersigned, do hereby certify thet I am legally authorized and entitled to take possesion of the vehicle described above and all personal property therein.`}</th>
                 
                </tr>
                <tr>
                  <th scope="row" colSpan="3" className='text-left'>Signature:</th>
                  <td><TextField fullWidth  id="SignDate" name='SignDate' type='datetime-local'  variant="standard" onChange={myChangeHandlersetdate2}  /></td>
                </tr>
                <tr>
                  <th scope="row" colSpan="3" className='text-left'>Method OF Payment:</th>
                  <td>
                        <select className="custom-select"  id="Payment"  onChange={myChangeHandlersetpayment} >
                            <option value=""></option>
                            <option value="Cash">Cash</option>
                            <option value="Visa / Credit">Visa / Credit</option>
                            <option value="Check">Check</option>
                            <option value="P.O.">P.O.</option>
                        </select>
                  </td>
                </tr>
                <tr>
                  <th scope="row" colSpan="5" className='text-center'>Police Hold- Mark Prior Damage</th>
                </tr>
                <tr>
                  <td className='text-center'>
                  <div className='text-center'  >
                             <Link to='/account'>
                              {
                                front1  ?
                                <img               
                               style={{width: '150px', height:80}}
                               src={front1}
                               alt='..'  /> :
                               <Spinner animation="border" role="status">
                                 <span className="visually-hidden">Loading...</span>
                               </Spinner>
                              }
                             </Link>
                         </div>
                  </td>
                  <td className='text-center'>
                        <div className='text-center'  >
                             <Link to='/account'>
                              {
                                rear1  ?
                                <img               
                               style={{width: '150px', height:80}}
                               src={rear1}
                               alt='..'  /> :
                               <Spinner animation="border" role="status">
                                 <span className="visually-hidden">Loading...</span>
                               </Spinner>
                              }
                             </Link>
                         </div>
                  </td>
                  <td className='text-center'>
                        <div className='text-center'  >
                             <Link to='/account'>
                              {
                                right1  ?
                                <img               
                               style={{width: '150px', height:80}}
                               src={right1}
                               alt='..'  /> :
                               <Spinner animation="border" role="status">
                                 <span className="visually-hidden">Loading...</span>
                               </Spinner>
                              }
                             </Link>
                         </div>
                  </td>
                  <td className='text-center'>
                        <div className='text-center'  >
                             <Link to='/account'>
                              {
                                left1  ?
                                <img               
                               style={{width: '150px', height:80}}
                               src={left1}
                               alt='..'  /> :
                               <Spinner animation="border" role="status">
                                 <span className="visually-hidden">Loading...</span>
                               </Spinner>
                              }
                             </Link>
                         </div>
                  </td>
                </tr>
                <tr>
                  <td className='text-center'>
                      <select className="custom-select"  id="Front" onChange={myChangeHandlersetfront}  >
                          <option value=""></option>
                          <option value="Yes (Front)">Yes (Front)</option>
                          <option value="No">No</option>
                      </select>
                  </td>
                  <td className='text-center'>
                      <select className="custom-select"  id="Rear"  onChange={myChangeHandlersetrear}  >
                          <option value=""></option>
                          <option value="Yes (Rear)">Yes (Rear)</option>
                          <option value="No">No</option>
                      </select>
                  </td>
                  <td className='text-center'>
                      <select className="custom-select"  id="Right"  onChange={myChangeHandlersetright} >
                          <option value=""></option>
                          <option value="Yes (Right)">Yes (Right)</option>
                          <option value="No">No</option>
                      </select>
                  </td>
                  <td className='text-center'>
                      <select className="custom-select"  id="Left"  onChange={myChangeHandlersetleft}  >
                          <option value=""></option>
                          <option value="Yes (Left)">Yes (Left)</option>
                          <option value="No">No</option>
                      </select>
                  </td>
                </tr>                
              </tbody>
            </table>
        </Row>
         <Row>
             <Col md={4} className='text-center' >
                 <span><strong>{todos[0] ? todos[0].bwebsite : ''}</strong></span>
             </Col>
             <Col md={4} className='text-center'>
                 <h5 ><strong>Thanks for choosing us.</strong></h5>
             </Col>
             <Col md={4} >
             <Row>
                 <Col>
                    <Button                                
                         type="button"
                          variant="outlined"
                          color="primary"
                          size="small"
                          className="primary"
                          startIcon={<SaveIcon />}   
                          onClick={authentication}               
                         >
                          Save
                     </Button>
                 </Col>
                 <Col>
                     <Button    
                            onClick={print}  
                             variant="outlined"
                             color="secondary"
                             size="small"
                             className="primary"
                             startIcon={<PrintIcon />}                  
                            >
                             Print
                        </Button>
                 </Col>

               </Row>
             </Col>
         </Row>
       
      </Container>


)}

export default InvoiceTowing