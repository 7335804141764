import Estimate from "../../models/Estimate"
export const SET_ESTIMATES = 'SET_ESTIMATES'

export const fetchEstimates = (user) => {
    return async dispatch => {
        try {
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}${user}/${process.env.REACT_APP_CREATE_ESTIMATE}/${user}/data.json`);
      
           if (!response.ok){
               throw new Error('Something went wrong!')
           }
      
           const resData = await response.json()
           const loadedEstimates = [];
             for(const key in resData) {
                loadedEstimates.push(new Estimate(
                     key, 
                     resData[key].Idinvoice,
                     resData[key].Company,
                     resData[key].Attn,
                     resData[key].Phone,
                     resData[key].Estimator,
                     resData[key].Project, 
                     resData[key].Date,
                     resData[key].Notes,
                     resData[key].Total,
                     resData[key].Lines_details 
                     ))
             }
          dispatch({ type:SET_ESTIMATES, estimates: loadedEstimates });
        } catch (err) {
          throw err;
        }
         
        } 
}