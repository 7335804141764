import React, {useState, useEffect} from 'react'
import { Container, Row, Col, Spinner  } from 'react-bootstrap'
import Axios from 'axios'
import {selectUser } from '../auth/users/userSlice'
import { useSelector } from 'react-redux'
import firebase from "firebase";
import { db } from '../auth/firebase'
import { Link } from 'react-router-dom';
import './InvoiceTowing.css'
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import PrintIcon from '@mui/icons-material/Print';
import SaveIcon from '@material-ui/icons/Save';

 
const  Estimate = (props) => { 


  const user = useSelector(selectUser)
  const [url, setUrl] = useState(null);
  const [todos, setTodos] = useState([]);

  const getProfileImage = () => {
    // firebase.storage().ref(`users/${user.uid}/cropped-GottaWebDesign-02-6-scaled-345x219-1-160x93.jpg`).getDownloadURL().then(imgUrl => {setUrl(imgUrl)})
    firebase
    .storage()
    .ref('users')
    .child(user.uid + "/logo.png")
    .getDownloadURL()
    .then((imgUrl) => {
      setUrl(imgUrl);
    });
  }

  useEffect(() => {
    setTimeout(() => {
      getProfileImage();
    }, 1000);
  }, [])



 // Get Todos
 const  getTodos = () => {
   db.collection(user.uid).onSnapshot(function(querySnapshot) {
     setTodos(
       querySnapshot.docs.map((doc) => ({
         id: doc.id,
         bcity: doc.data().bcity,
         bemail: doc.data().bemail,
         bstreet: doc.data().bstreet,
         businessname: doc.data().businessname,
         bphone: doc.data().bphone,
         bwebsite: doc.data().bwebsite
       }))
     );
   });
 }

 useEffect(() => {
  getTodos();    
}, []) // blank to run only on first time


const[ultinvoice, setUltinvoice] = useState(null)
const ulimoinv = useSelector(state => state.estimates.estimates)

useEffect(() => {
  const rel =  ulimoinv[ulimoinv.length - 1]
  setUltinvoice(rel)
},[ulimoinv])





  const [idinvoice, setidinvoice] = useState('')
  const [company,      setcompany] = useState('')
  const [attn,   setattn] = useState('')
  const [phone,     setphone] = useState('')
  const [estimator,   setestimator] = useState('')
  const [projectname,   setprojectname] = useState('')
  const [date,      setdate] = useState('')
  
  const [select1  ,  setselect1 ] =  useState('')
  const [select2  ,  setselect2 ] =  useState('')
  const [select3  ,  setselect3 ] =  useState('')
  const [select4  ,  setselect4 ] =  useState('')
  const [select5  ,  setselect5 ] =  useState('')
  const [select6  ,  setselect6 ] =  useState('')
  const [select7  ,  setselect7 ] =  useState('')

  const [detail1 ,  setdetail1 ] = useState('')
  const [detail2 ,  setdetail2 ] = useState('')
  const [detail3 ,  setdetail3 ] = useState('')
  const [detail4 ,  setdetail4 ]  = useState('')
  const [detail5 ,  setdetail5 ]  = useState('')
  const [detail6 ,  setdetail6 ] = useState('')
  const [detail7 ,  setdetail7 ] = useState('')

  const [quantity1 ,  setquantity1 ] = useState(0)
  const [quantity2 ,  setquantity2 ] = useState(0)
  const [quantity3 ,  setquantity3 ] = useState(0)
  const [quantity4 ,  setquantity4 ] = useState(0)
  const [quantity5 ,  setquantity5 ] = useState(0)
  const [quantity6 ,  setquantity6 ] = useState(0)
  const [quantity7 ,  setquantity7 ] = useState(0)

  const [price1 ,  setprice1 ] = useState(0)
  const [price2 ,  setprice2 ] = useState(0)
  const [price3 ,  setprice3 ] = useState(0)
  const [price4 ,  setprice4 ] = useState(0)
  const [price5 ,  setprice5 ] = useState(0)
  const [price6 ,  setprice6 ] = useState(0)
  const [price7 ,  setprice7 ] = useState(0)

  const [note ,  setnote ] = useState('')

  const [total,     setdtotal] = useState('')


    
    //  function myChangeHandlerbalance() { 
       
    //   setbalance(
        
    //       ((((pri1 ?  parseInt(pri1) * parseInt(qua1) : 0) ) + 
    //         ((pri2 ?  parseInt(pri2) * parseInt(qua2) : 0) ) +
    //         ((pri3 ?  parseInt(pri3) * parseInt(qua3) : 0) ) + 
    //         ((pri4 ?  parseInt(pri4) * parseInt(qua4) : 0) ) + 
    //         ((pri5 ?  parseInt(pri5) * parseInt(qua5) : 0) ) + 
    //         ((pri6 ?  parseInt(pri6) * parseInt(qua6) : 0) )) - (amo ? parseInt(amo) : 0)).toFixed(2)
    //   )
    
    // }


    function myChangeHandleridinvoice(event) {setidinvoice(event.target.value)}
    function myChangeHandlercompany(event) {setcompany(event.target.value)}
    function myChangeHandlerattn(event) {setattn(event.target.value)}
    function myChangeHandlerphone(event) {setphone(event.target.value)}
    function myChangeHandlerestimator(event) {setestimator(event.target.value)}
    function myChangeHandlerprojectname(event) {setprojectname(event.target.value)}
    function myChangeHandlerdate(event) {setdate(event.target.value)}
     

     function myChangeHandlersetselect1(event) { setselect1(event.target.value)}
     function myChangeHandlersetselect2(event) { setselect2(event.target.value)}     
     function myChangeHandlersetselect3(event) { setselect3(event.target.value)}      
     function myChangeHandlersetselect4(event) { setselect4(event.target.value)}
     function myChangeHandlersetselect5(event) { setselect5(event.target.value)}
     function myChangeHandlersetselect6(event) { setselect6(event.target.value)}    
     function myChangeHandlersetselect7(event) { setselect7(event.target.value)} 

     function myChangeHandlersetdetail1(event) { setdetail1(event.target.value)}
     function myChangeHandlersetdetail2(event) { setdetail2(event.target.value)}
     function myChangeHandlersetdetail3(event) { setdetail3(event.target.value)}
     function myChangeHandlersetdetail4(event) { setdetail4(event.target.value)}
     function myChangeHandlersetdetail5(event) { setdetail5(event.target.value)}
     function myChangeHandlersetdetail6(event) { setdetail6(event.target.value)}
     function myChangeHandlersetdetail7(event) { setdetail7(event.target.value)}

     function myChangeHandlersetquantity1(event) { setquantity1(event.target.value)}
     function myChangeHandlersetquantity2(event) { setquantity2(event.target.value)}
     function myChangeHandlersetquantity3(event) { setquantity3(event.target.value)}
     function myChangeHandlersetquantity4(event) { setquantity4(event.target.value)}
     function myChangeHandlersetquantity5(event) { setquantity5(event.target.value)}
     function myChangeHandlersetquantity6(event) { setquantity6(event.target.value)}
     function myChangeHandlersetquantity7(event) { setquantity7(event.target.value)}

     function myChangeHandlersetprice1(event) { setprice1(event.target.value)}
     function myChangeHandlersetprice2(event) { setprice2(event.target.value)}
     function myChangeHandlersetprice3(event) { setprice3(event.target.value)}
     function myChangeHandlersetprice4(event) { setprice4(event.target.value)}
     function myChangeHandlersetprice5(event) { setprice5(event.target.value)}
     function myChangeHandlersetprice6(event) { setprice6(event.target.value)}
     function myChangeHandlersetprice7(event) { setprice7(event.target.value)}

     function myChangeHandlersetnote(event) { setnote(event.target.value)}


     function print(){
         window.print()
       }


       const authentication = () => {          
 
       const data = {

          "Idinvoice": ultinvoice != null ? parseInt(ultinvoice.Idinvoice) + 1 : '',
          "Company": company, 
          "Attn": attn, 
          "Phone": phone,    
          "Estimator": estimator,     
          "Project": projectname,   
          "Date": date,  
          "Notes":note,
          "Total": 
            
            ((quantity1 ?  parseInt(quantity1) * parseInt(price1) : 0) +
            ( quantity2 ?  parseInt(quantity2) * parseInt(price2) : 0) + 
            ( quantity3 ?  parseInt(quantity3) * parseInt(price3) : 0) + 
            ( quantity4 ?  parseInt(quantity4) * parseInt(price4) : 0) + 
            ( quantity5 ?  parseInt(quantity5) * parseInt(price5) : 0) +
            ( quantity6 ?  parseInt(quantity6) * parseInt(price6) : 0) +
            ( quantity7 ?  parseInt(quantity7) * parseInt(price7) : 0)).toFixed(2)    ,
          "Lines_details": [         
            {
                "Des":  select1,
                "Dess": detail1,
                "Qua": quantity1,
                "Pri": price1
            },
            {
              "Des": select2,
              "Dess": detail2,
              "Qua": quantity2,
              "Pri": price2
            },
            {
              "Des":  select3,
              "Dess": detail3,
              "Qua": quantity3,
              "Pri": price3,
             },
             {
               "Des": select4,
               "Dess": detail4,
               "Qua": quantity4,
               "Pri": price4,
             },
             {
              "Des":   select5,
              "Dess":  detail5,
              "Qua": quantity5,
              "Pri":    price5
            },
            {
              "Des":   select6,
              "Dess":  detail6,
              "Qua": quantity6,
              "Pri":    price6,
             },
             {
               "Des":  select7,
               "Dess": detail7,
               "Qua":quantity7,
               "Pri":   price7,
             }
             ]   
        
      }  


      Axios.post(`${process.env.REACT_APP_BASE_URL}${user.uid}/${process.env.REACT_APP_CREATE_ESTIMATE}/${user.uid}/data.json`, data)
        .then(r => {
          localStorage.setItem('token', r.data.token)
          window.location = "/"
        }).then(() => alert('The Invoice has been succesfully saved'))
        .catch(e => {
          alert('error al iniciar sesion')
        } )
      }

    return (

        <Container style={{marginTop:'40px'}} >                        
          <Row className='mb-3'>
              <table className=" table-borderless">
                <thead>
                  <tr>
                    <th  >
                      <div className='text-center'  >
                             <Link to='/account'>
                              {
                                url != null ?
                                <img               
                               style={{width: '200px', height:150}}
                               src={url}
                               alt='..'  /> :
                               <Spinner animation="border" role="status">
                                 <span className="visually-hidden">Loading...</span>
                               </Spinner>
                              }
                             </Link>
                         </div>
                      </th>
                    <th  >
                      <div className="text-left" >
                         <h2 className="text-center">{todos[0] ? todos[0].businessname : ''}</h2>
                            <p className="text-center">{todos[0] ? todos[0].bstreet : ''}<br/>
                            {todos[0] ? todos[0].bcity : ''}<br/>{todos[0] ? todos[0].bphone : ''}</p>
                      </div>                         
                    </th>
                    <th  > 
                      <div className='text-center'>
                        <h3 >Estimate No:</h3><br></br>
                        <h3>{ultinvoice != null ? parseInt(ultinvoice.Idinvoice) + 1 : ''}</h3>
                      </div>
                    </th>
                    {/* <th ></th> */}
                  </tr>
                </thead>
                <tbody>
                  <tr><td></td></tr>
                  <tr><td></td></tr>
                  <tr><td></td></tr>

                </tbody>               
              </table>
          </Row>
         
         
        <Row>
            <table className="table  table-sm">
              {/* <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">First</th>
                  <th scope="col">Last</th>
                  <th scope="col">Handle</th>
                </tr>
              </thead> */}
              <tbody>
                 <tr>
                  <td ><TextField fullWidth InputProps={{ disableUnderline: true }} id="Company" name='Company' type='text' label="Company" variant="standard" onChange={myChangeHandlercompany} /></td>
                  <td><TextField fullWidth  InputProps={{ disableUnderline: true }} id="Attn" name='Attn' type='text' label="Attn" variant="standard" onChange={myChangeHandlerattn} /> </td>
                  <td ><TextField fullWidth InputProps={{ disableUnderline: true }} id="Phone" name='Phone' type='text'  label="Phone Number" variant="standard" onChange={myChangeHandlerphone}  /></td>
                </tr>
                <tr>                  
                  <td><TextField fullWidth InputProps={{ disableUnderline: true }} id="Estimator" name='Estimator' type='text' label="Estimator" variant="standard" onChange={myChangeHandlerestimator} /></td>                  
                  <td><TextField fullWidth InputProps={{ disableUnderline: true }} id="ProjectName" name='ProjectName' type='text' label="Project Name" variant="standard" onChange={myChangeHandlerprojectname} /></td>
                  <td><TextField fullWidth InputProps={{ disableUnderline: true }} format="MM/dd/yyyy" id="Date" name='Date' type='date' label="Date" variant="standard" onChange={myChangeHandlerdate} /></td> 
                </tr>
                </tbody>
                </table>
                {/* <h6 className='text-center'>Descriptions</h6> */}
                <table className="table  table-sm ">            
              <tbody>
                <tr className='text-center'>
                  <th colSpan="3" >Description</th>
                  <th style={{width:'130px'}} >Quantity</th>
                  <th style={{width:'130px'}} >Price $</th>
                  <th scope="row" style={{width:'130px'}}>Sub Total</th>
                 </tr>
                <tr >
                  <th scope="row" style={{width:'180px'}}>
                        <select className="custom-select"  id="Select1"  onChange={(myChangeHandlersetselect1)} >
                            <option value=""></option>
                            <option value="Material">Material</option>
                            <option value="Labor">Labor</option>
                            <option value="Galvanizing">Galvanizing</option>
                            <option value="Shipping">Shipping</option>
                            <option value="Detailing">Detailing</option>
                            <option value="Printing">Printing</option>
                            <option value="Painting">Painting</option>
                            <option value="Material-2">Material - 2</option>
                            <option value="Material-3">Material - 3</option>
                            <option value="Material-4">Material - 4</option>
                            <option value="Accesories">Accesories</option>
                            <option value="Accesories">Other Charges</option>
                        </select>
                  </th>
                  <td colSpan="2"><TextField multiline fullWidth InputProps={{ disableUnderline: true}}  id="Detail1" name='Detail1' type='text'  variant="standard"  onChange={myChangeHandlersetdetail1} /></td>
                  <td ><TextField  fullWidth InputProps={{ disableUnderline: true }}  inputProps={{min: 0, style: { textAlign: 'center' }}}  id="Quantity1" name='Quantity1' type='number'  variant="standard"  onChange={myChangeHandlersetquantity1} /></td>
                  <td ><TextField  fullWidth InputProps={{ disableUnderline: true }}  inputProps={{min: 0, style: { textAlign: 'center' }}}  id="Price1" name='Price1' type='number'  variant="standard"  onChange={myChangeHandlersetprice1} /></td>
                  <td><TextField   fullWidth InputProps={{ disableUnderline: true }}  inputProps={{min: 0, style: { textAlign: 'center' }}}  id="Stotal1" name='Stotal1' type='number'  variant="standard" value={((quantity1 ?  parseInt(quantity1) * parseInt(price1) : 0) ).toFixed(2)} /></td>
                </tr>
                <tr>
                  <th scope="row">
                  <select className="custom-select"  id="Select2"  onChange={myChangeHandlersetselect2} >
                            <option value=""></option>
                            <option value="Material">Material</option>
                            <option value="Labor">Labor</option>
                            <option value="Galvanizing">Galvanizing</option>
                            <option value="Shipping">Shipping</option>
                            <option value="Detailing">Detailing</option>
                            <option value="Printing">Printing</option>
                            <option value="Painting">Painting</option>
                            <option value="Material-2">Material - 2</option>
                            <option value="Material-3">Material - 3</option>
                            <option value="Material-4">Material - 4</option>
                            <option value="Accesories">Accesories</option>
                            <option value="Accesories">Other Charges</option>
                        </select>
                  </th>
                  <td colSpan="2"><TextField multiline fullWidth InputProps={{ disableUnderline: true }} id="Detail1" name='Detail1' type='text'  variant="standard"  onChange={myChangeHandlersetdetail2} /></td>
                  <td><TextField fullWidth InputProps={{ disableUnderline: true }} inputProps={{min: 0, style: { textAlign: 'center' }}} id="Quantity2" name='Quantity2' type='number'  variant="standard" onChange={myChangeHandlersetquantity2} /></td>
                  <td><TextField fullWidth InputProps={{ disableUnderline: true }} inputProps={{min: 0, style: { textAlign: 'center' }}} id="Price2" name='Price2' type='number'  variant="standard" onChange={myChangeHandlersetprice2} /></td>
                  <td><TextField fullWidth InputProps={{ disableUnderline: true }} inputProps={{min: 0, style: { textAlign: 'center' }}} id="Stotal2" name='Stotal2' type='number'  variant="standard" value={((quantity2 ?  parseInt(quantity2) * parseInt(price2) : 0) ).toFixed(2)}  /></td>
                </tr>
                <tr>
                  <th scope="row">
                  <select className="custom-select"  id="Select3"  onChange={myChangeHandlersetselect3} >
                            <option value=""></option>
                            <option value="Material">Material</option>
                            <option value="Labor">Labor</option>
                            <option value="Galvanizing">Galvanizing</option>
                            <option value="Shipping">Shipping</option>
                            <option value="Detailing">Detailing</option>
                            <option value="Printing">Printing</option>
                            <option value="Painting">Painting</option>
                            <option value="Material-2">Material - 2</option>
                            <option value="Material-3">Material - 3</option>
                            <option value="Material-4">Material - 4</option>
                            <option value="Accesories">Accesories</option>
                            <option value="Accesories">Other Charges</option>
                        </select>
                  </th>
                  <td colSpan="2"><TextField multiline fullWidth InputProps={{ disableUnderline: true }} id="Detail3" name='Detail3' type='text'  variant="standard" onChange={myChangeHandlersetdetail3} /> </td>
                  <td><TextField fullWidth InputProps={{ disableUnderline: true }} inputProps={{min: 0, style: { textAlign: 'center' }}}   id="Quantity3" name='Quantity3' type='number'  variant="standard" onChange={myChangeHandlersetquantity3} /></td>
                  <td><TextField fullWidth InputProps={{ disableUnderline: true }} inputProps={{min: 0, style: { textAlign: 'center' }}}   id="Price3" name='Price3' type='number'  variant="standard" onChange={myChangeHandlersetprice3} /></td>
                  <td><TextField fullWidth InputProps={{ disableUnderline: true }} inputProps={{min: 0, style: { textAlign: 'center' }}}    id="Stotal3" name='Stotal3' type='number'  variant="standard" value={((quantity3 ?  parseInt(quantity3) * parseInt(price3) : 0) ).toFixed(2)} /></td>
                </tr>                
                <tr>
                  <th scope="row">
                  <select className="custom-select"  id="Select4"  onChange={myChangeHandlersetselect4} >
                            <option value=""></option>
                            <option value="Material">Material</option>
                            <option value="Labor">Labor</option>
                            <option value="Galvanizing">Galvanizing</option>
                            <option value="Shipping">Shipping</option>
                            <option value="Detailing">Detailing</option>
                            <option value="Printing">Printing</option>
                            <option value="Painting">Painting</option>
                            <option value="Material-2">Material - 2</option>
                            <option value="Material-3">Material - 3</option>
                            <option value="Material-4">Material - 4</option>
                            <option value="Accesories">Accesories</option>
                            <option value="Accesories">Other Charges</option>
                        </select>
                  </th>
                  <td colSpan="2"><TextField multiline fullWidth InputProps={{ disableUnderline: true }} id="Detail4" name='Detail4' type='text'  variant="standard" onChange={myChangeHandlersetdetail4} /></td>
                  <td><TextField fullWidth InputProps={{ disableUnderline: true }}  inputProps={{min: 0, style: { textAlign: 'center' }}} id="Quantity4" name='Quantity4' type='number'  variant="standard" onChange={myChangeHandlersetquantity4} /></td>
                  <td><TextField fullWidth InputProps={{ disableUnderline: true }}  inputProps={{min: 0, style: { textAlign: 'center' }}} id="Price4" name='Price4' type='number'  variant="standard" onChange={myChangeHandlersetprice4} /></td>
                  <td><TextField fullWidth InputProps={{ disableUnderline: true }}  inputProps={{min: 0, style: { textAlign: 'center' }}} id="Stotal4" name='Stotal4' type='number'  variant="standard" value={((quantity4 ?  parseInt(quantity4) * parseInt(price4) : 0) ).toFixed(2)}  /></td>
                </tr>
                <tr>
                  <th scope="row">
                  <select className="custom-select"  id="Select5"  onChange={myChangeHandlersetselect5} >
                            <option value=""></option>
                            <option value="Material">Material</option>
                            <option value="Labor">Labor</option>
                            <option value="Galvanizing">Galvanizing</option>
                            <option value="Shipping">Shipping</option>
                            <option value="Detailing">Detailing</option>
                            <option value="Printing">Printing</option>
                            <option value="Painting">Painting</option>
                            <option value="Material-2">Material - 2</option>
                            <option value="Material-3">Material - 3</option>
                            <option value="Material-4">Material - 4</option>
                            <option value="Accesories">Accesories</option>
                            <option value="Accesories">Other Charges</option>
                        </select>
                  </th>
                  <td colSpan="2"><TextField multiline fullWidth InputProps={{ disableUnderline: true }} id="Detail5" name='Detail5' type='text'  variant="standard"  onChange={myChangeHandlersetdetail5} /></td>
                  <td><TextField fullWidth InputProps={{ disableUnderline: true }} inputProps={{min: 0, style: { textAlign: 'center' }}} id="Quantity5" name='Quantity5' type='number'  variant="standard" onChange={myChangeHandlersetquantity5} /></td>
                  <td><TextField fullWidth InputProps={{ disableUnderline: true }} inputProps={{min: 0, style: { textAlign: 'center' }}} id="Price5" name='Price5' type='number'  variant="standard" onChange={myChangeHandlersetprice5} /></td>
                  <td><TextField fullWidth InputProps={{ disableUnderline: true }} inputProps={{min: 0, style: { textAlign: 'center' }}} id="Stotal5" name='Stotal5' type='number'  variant="standard" value={((quantity5 ?  parseInt(quantity5) * parseInt(price5) : 0) ).toFixed(2)}  /></td>
                </tr>
                <tr>
                  <th scope="row">
                  <select className="custom-select"  id="Select6"  onChange={myChangeHandlersetselect6} >
                            <option value=""></option>
                            <option value="Material">Material</option>
                            <option value="Labor">Labor</option>
                            <option value="Galvanizing">Galvanizing</option>
                            <option value="Shipping">Shipping</option>
                            <option value="Detailing">Detailing</option>
                            <option value="Printing">Printing</option>
                            <option value="Painting">Painting</option>
                            <option value="Material-2">Material - 2</option>
                            <option value="Material-3">Material - 3</option>
                            <option value="Material-4">Material - 4</option>
                            <option value="Accesories">Accesories</option>
                            <option value="Accesories">Other Charges</option>
                        </select>
                  </th>
                  <td colSpan="2"><TextField multiline fullWidth InputProps={{ disableUnderline: true }} id="Detail6" name='Detail6' type='text'  variant="standard"  onChange={myChangeHandlersetdetail6} /></td>
                  <td><TextField fullWidth InputProps={{ disableUnderline: true }} inputProps={{min: 0, style: { textAlign: 'center' }}} id="Quantity6" name='Quantity6' type='number'  variant="standard" onChange={myChangeHandlersetquantity6} /></td>
                  <td><TextField fullWidth InputProps={{ disableUnderline: true }} inputProps={{min: 0, style: { textAlign: 'center' }}} id="Price6" name='Price6' type='number'  variant="standard" onChange={myChangeHandlersetprice6} /></td>
                  <td><TextField fullWidth InputProps={{ disableUnderline: true }} inputProps={{min: 0, style: { textAlign: 'center' }}} id="Stotal6" name='Stotal6' type='number'  variant="standard" value={((quantity6 ?  parseInt(quantity6) * parseInt(price6) : 0) ).toFixed(2)} /></td>
                </tr>
                <tr>
                  <th scope="row"> 
                  <select className="custom-select"  id="Select7"  onChange={myChangeHandlersetselect7} >
                            <option value=""></option>
                            <option value="Material">Material</option>
                            <option value="Labor">Labor</option>
                            <option value="Galvanizing">Galvanizing</option>
                            <option value="Shipping">Shipping</option>
                            <option value="Detailing">Detailing</option>
                            <option value="Printing">Printing</option>
                            <option value="Painting">Painting</option>
                            <option value="Material-2">Material - 2</option>
                            <option value="Material-3">Material - 3</option>
                            <option value="Material-4">Material - 4</option>
                            <option value="Accesories">Accesories</option>
                            <option value="Accesories">Other Charges</option>
                        </select>
                  </th>
                  <td colSpan="2"><TextField multiline fullWidth InputProps={{ disableUnderline: true }} id="Other-text" name='Other-text' type='text' label="" variant="standard" onChange={myChangeHandlersetdetail7} /></td>
                  <td><TextField fullWidth InputProps={{ disableUnderline: true }} inputProps={{min: 0, style: { textAlign: 'center' }}} id="Other" name='Other' type='number' label="" variant="standard" onChange={myChangeHandlersetquantity7} /></td>
                  <td><TextField fullWidth InputProps={{ disableUnderline: true }} inputProps={{min: 0, style: { textAlign: 'center' }}} id="Other" name='Other' type='number' label="" variant="standard" onChange={myChangeHandlersetprice7} /></td>
                  <td><TextField fullWidth InputProps={{ disableUnderline: true }} inputProps={{min: 0, style: { textAlign: 'center' }}} id="Other" name='Other' type='number' label="" variant="standard"value={((quantity7 ?  parseInt(quantity7) * parseInt(price7) : 0) ).toFixed(2)} /></td>
                </tr>
                <tr>
                  <th scope="row" colSpan="5" className='text-center'><h5>TOTAL $</h5></th>
                  <th className='text-center'> 
                  
                    {
                    ((quantity1 ?  parseInt(quantity1) * parseInt(price1) : 0) +
                    ( quantity2 ?  parseInt(quantity2) * parseInt(price2) : 0) + 
                    ( quantity3 ?  parseInt(quantity3) * parseInt(price3) : 0) + 
                    ( quantity4 ?  parseInt(quantity4) * parseInt(price4) : 0) + 
                    ( quantity5 ?  parseInt(quantity5) * parseInt(price5) : 0) +
                    ( quantity6 ?  parseInt(quantity6) * parseInt(price6) : 0) +
                    ( quantity7 ?  parseInt(quantity7) * parseInt(price7) : 0)).toFixed(2)
                    }
                                    
                  </th>                  
                </tr>
                <tr>
                  <td colSpan="6">
                  <h6>Notes:</h6>
                  <textarea  
                    style={{ borderColor:'white', width:'100%',  height:'100px', resize:'vertical'}}
                     id="Notes" 
                     name="Notes" 
                     type='text' 
                     onChange={myChangeHandlersetnote}
                  />  
                  </td>
                </tr>        
              </tbody>
            </table>
        </Row>
         <Row>
             <Col md={4} className='text-center' >
                 <span><strong>{todos[0] ? todos[0].bwebsite : ''}</strong></span>
             </Col>
             <Col md={4} className='text-center'>
                 <h5 ><strong>Thanks for choosing us.</strong></h5>
             </Col>
             <Col md={4} >
             <Row>
                 <Col>
                    <Button                                
                         type="button"
                          variant="outlined"
                          color="primary"
                          size="small"
                          className="primary"
                          startIcon={<SaveIcon />}   
                          onClick={authentication}               
                         >
                          Save
                     </Button>
                 </Col>
                 <Col>
                     <Button    
                            onClick={print}  
                             variant="outlined"
                             color="secondary"
                             size="small"
                             className="primary"
                             startIcon={<PrintIcon />}                  
                            >
                             Print
                        </Button>
                 </Col>

               </Row>
             </Col>
         </Row>
       
      </Container>


)}

export default Estimate