import React from 'react'
import './Account.css'
import { Link, useHistory } from 'react-router-dom'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import { useDispatch, useSelector } from 'react-redux'
import { logout, selectUser } from '../auth/users/userSlice'
import { auth } from '../auth/firebase'
import NavigateAccount from '../components/NavigateAccount'
import {businessname,  blogo} from '../models/European'


function Account({ isMenuOpen, setIsMenuOpen }) {
  const user = useSelector(selectUser)
  const dispatch = useDispatch()
  const history = useHistory()

  const logoutOfApp = () => {
    auth
      .signOut()
      .then(() => {
        dispatch(logout())
        history.push('/')
      })
      .catch((error) => alert(error.message))
  }


  return (
    <>   
    <div className='teslaAccount'>
      <div className='teslaAccount__header'>
        <div className='teslaAccount__logo'>
          <Link to='/'>
            <img
               src={blogo}
                 alt=''
            />
          </Link>
        </div>
        <div className='teslaAccount__links'>
          <Link to="/" onClick={logoutOfApp}>Log out</Link>
          <div
            className='teslaAccount__menu'
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? (
              <CloseIcon className='teslaAccount__closeMenu' />
            ) : (
              <MenuIcon />
            )}
          </div>
        </div>
      </div>
      <div className='teslaAccount__info'>
        <div className='teslaAccount__person'>
          <h4>{"Hello " + "Elmer"  }</h4>
        </div>
        <div className='teslaAccount__infoRight'>
          {/* <Link to="/account">Account</Link>
          <Link to="/invoice">Invoice</Link>
          <Link to="/report">Reports</Link>
          <Link  to="/" onClick={logoutOfApp}>Sign out</Link> */}
        </div>
      </div>
      <div className='teslaAccount__car'>
        {/* <Invoice />        */}
        {/* <InvoiceLast /> */}
        <NavigateAccount />
      </div>
    </div>
    </>
  )
}

export default Account