class Estimate {
    constructor(id, Idinvoice, Company, Attn, Phone, Estimator, Project, Date, Notes, Total,  Lines_details ) {
           this.id = id;
           this.Idinvoice = Idinvoice;
           this.Company = Company;
           this.Attn = Attn;
           this.Phone = Phone;
           this.Estimator = Estimator;
           this.Project = Project;
           this.Date = Date;
           this.Notes = Notes;
           this.Total = Total;
           this.Lines_details = Lines_details;
    }
  }
  
  export default Estimate;